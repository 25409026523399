#main {
  background-color: #282c34;
  color: white;
  text-shadow: 1px 1px 1px black;
  font-size: 18px;
  width: 100%;
  margin: auto;
}

#heading {
  background: linear-gradient(rgb(68, 55, 55), rgb(29, 29, 29));
  border-radius: 5px;
  margin: 5px;
  width: auto;
  text-align: center;
  text-shadow: 2px 2px 2px black;
  border-bottom: 2px solid black;
}

.carousel {
  border-radius: 1%;
  background-color: black;
  width: 100%;
  position: relative;
  border: 5px solid;
  border-style: inset;
  border-color: rgb(80, 80, 80);
  box-shadow: 8px 8px 10px rgb(31, 29, 29);
  box-sizing: border-box;
}

#certs {
  width: auto;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: inherit;
  max-width: 100%;
}

#certBorder {
  border: groove black 1px;
  background-color: black;
  box-shadow: 2px 2px 6px lightblue;
  border-radius: 5%;
  width: auto;
  height: 120px;
  /* box-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #0fa,
    0 0 82px #0fa,
    0 0 92px #0fa,
    0 0 102px #0fa,
    0 0 151px #0fa; */
}
.carousel-item > img {
  width: auto;
  position: relative;
  height: 700px;
  max-height: 500px;
  margin: 2px;
}

#listtable {
  border-style: ridge;
  border-radius: 5px;
  border: 3px solid grey;
  box-shadow: 2px 2px 2px black;
  background: rgb(83, 82, 82);
  margin: 6px;
  margin-bottom: 2rem;
}

.infobox {
  margin: 2rem;
  font-size: large;
}
#youknow {
  border: 3px solid rgba(255, 255, 255, 0.822);
  border-style: ridge;
  box-shadow: 4px 4px 10px grey;
  width: 100%;
  max-width: 450px;
  height: auto;
}

#navBar {
  background-color: rgb(112, 112, 112);
  min-height: 75px;
  border-bottom: 8px solid darkred;
  border-bottom-style: ridge;
  text-shadow: 1px 1px 1px black;
  font-size: 18px;
}

.site-footer {
  color: white;
  background-color: rgb(112, 112, 112);
  flex-direction: row;
  white-space: nowrap;
  font-size: 14px;
  margin: auto;
  padding: 25px;
  border-top: 8px solid darkred;
  border-top-style: ridge;
  text-shadow: 1px 1px 1px black;
}

ul#footer li {
  display: inline;
}

ul.a  {
  list-style-type: circle;
}

#top {
  margin-bottom: 50px;
}

/* #homePic {
  width: 100%;
  max-width: 500px;
  height: auto;
  border: 7px solid grey;
  border-style: outset;
  box-shadow: 5px 4px 10px black;
  border-radius: 3%/3%;
} */
